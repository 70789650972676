<template>
    <BaseLayout>
        <template v-slot:page-content>
            <div class="container-fluid">
                <BreadCrumb :current-page="$t('nav.help')"/>
            </div>
            <div class="all page_wrap">
                <section class="container-fluid px-lg-4 custom-wrapper">
                    <div class="mr-custom ml-custom">
                        <h2 class="sub-header mb-3">{{$t('nav.help')}}</h2>

                        <div class="content">
                            {{settings.support || ''}}

                        </div>
                    </div>
                </section>
            </div>
        </template>
    </BaseLayout>
</template>

<script setup>
import BaseLayout from "@/containers/BaseLayout";
import BreadCrumb from "@/components/BreadCrumb";
import {useStore} from 'vuex';
import {computed} from "vue";
const store = useStore();
const settings = computed(()=>store.getters.settings)
</script>

<style scoped>

</style>